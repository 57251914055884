import React, { useContext, useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { AuthContext } from './Auth';
import app from './base';

function Login({ history }) {
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = () => {
    setError('');
    setIsLoading(true);
    const authentication = getAuth(app);
    signInWithEmailAndPassword(authentication, email, pass)
      .then((userCredential) => {
        // eslint-disable-next-line no-unused-vars
        const { user } = userCredential;
        history.push('/shorten_url');
      })
      .catch(() => {
        setError('Invalid Email or Password');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/shorten" />;
  }

  return (
    <div className="col-lg-6 col-md-6 mb-4">
      <div className="login-area">
        <h4 className="text-secondary">Login</h4>
        <div className="col">
          <label />
          <div className="input-group">
            <input
              className="form-control"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </div>
          <div className="input-group">
            <input
              className="form-control"
              type="password"
              placeholder="Password"
              onChange={(e) => setPass(e.target.value)}
            />
          </div>
          <div
            className="text-left"
            style={{ marginTop: '1rem', display: 'flex' }}
          >
            <button
              type="button"
              className="btn btn-primary btnhover w-100 me-2"
              disabled
              onClick={handleLogin}
            >
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </div>
          {error && <p className="text-danger">{error}</p>}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Login);
