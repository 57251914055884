/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Collapse from 'react-bootstrap/Collapse';
import MenuListArray2 from './MenuListArray2';

function Header() {
  /* for sticky header */
  const [headerFix, setheaderFix] = React.useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  /* for open menu Toggle btn  */
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const showSidebar = () => setSidebarOpen(!sidebarOpen);
  /*  Toggle btn End  */

  useEffect(() => {
    const mainMenu = document.getElementById('OpenMenu');
    if (mainMenu) {
      if (sidebarOpen) {
        mainMenu.classList.add('show');
      } else {
        mainMenu.classList.remove('show');
      }
    }
  });

  // Menu dropdown list
  const [active, setActive] = useState('Home');
  const handleMenuActive = (status) => {
    setActive(status);
    if (active === status) {
      setActive('');
    }
  };

  // Menu dropdown list End
  return (
    <header className="site-header mo-left header style-1">
      <div
        className={`sticky-header main-bar-wraper navbar-expand-lg ${headerFix ? 'is-fixed' : ''}`}
      >
        <div className="main-bar clearfix">
          <div className="container clearfix">
            {/* <!-- Website Logo --> */}
            <div className="logo-header logo-dark">
              <Link to="/">
                {/* <img src={logo} alt="logo" /> */}
                <h5>Crisp URL</h5>
              </Link>
            </div>

            {/* <!-- Nav Toggle Button --> */}
            <button
              type="button"
              className={`navbar-toggler collapsed navicon justify-content-end ${sidebarOpen ? 'open' : ''}`}
              onClick={showSidebar}
            >
              <span />
              <span />
              <span />
            </button>

            {/* <!-- Main Nav --> */}
            <div
              className={`header-nav navbar-collapse collapse justify-content-end ${sidebarOpen ? 'show' : ''}`}
              id="navbarNavDropdown"
            >
              <div className="logo-header logo-dark">
                <Link to="/">
                  {/* <img src={logo} alt="logo-bnt" /> */}
                  <h5>Crisp URL</h5>
                </Link>
              </div>

              <ul className="nav navbar-nav">
                {MenuListArray2.map((data, index) => {
                  const isOpen = active === data.title && data.content;
                  const hasContent = Boolean(data.content);

                  const classList = ['sub-menu-down'];

                  if (isOpen) {
                    classList.push('open');
                  } else if (!hasContent) {
                    // The 'sub-menu-down' class should be removed when data.content is falsy
                    classList.pop();
                  }

                  const className = classList.join(' ');

                  return (
                    <li key={index} className={className}>
                      <Link
                        to={hasContent ? '#' : data.to}
                        onClick={() => handleMenuActive(data.title)}
                      >
                        <span>{data.title}</span>
                      </Link>
                      {hasContent && (
                        <Collapse in={active === data.title}>
                          <ul className="sub-menu">
                            {data.content && data.content.map((subData, subIndex) => (
                              <li key={subIndex}>
                                <Link to={subData.to}>{subData.title}</Link>
                              </li>
                            ))}
                          </ul>
                        </Collapse>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
