import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import app from './base';

function Logout() {
  const history = useHistory();
  const auth = getAuth(app);

  useEffect(() => {
    signOut(auth)
      .then(() => {
        history.push('/');
      })
      .catch(() => {
        // console.error('Error signing out: ', error);
      });
  }, [history, auth]);

  return (
    <div>
      Logging out...
    </div>
  );
}

export default Logout;
