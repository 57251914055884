import { useHistory } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../Auth';

function LinkResult({ inputValue }) {
  const [shortenLink, setShortenLink] = useState(null);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_SERVER_API_URL || 'http://localhost/';
  const history = useHistory();

  const { currentUser } = useContext(AuthContext);

  const handleRedirect = () => {
    if (shortenLink) {
      window.location.href = shortenLink.shortened_url;
    }
  };

  const handleCopy = () => {
    if (shortenLink) {
      navigator.clipboard.writeText(shortenLink.shortened_url)
        .then(() => setCopied(true))
        .catch(() => null);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const token = currentUser.accessToken;
        if (!token) {
          history.push('/');
        }

        const res = await axios.post(
          `${apiUrl}shorten_url`,
          { long_url: inputValue },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setShortenLink(res.data);
      } catch (err) {
        setError(err.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    if (inputValue.length) {
      fetchData();
    }
  }, [inputValue, currentUser.accessToken, apiUrl, history]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <>
      {loading && (
        <div className="loader" style={{ marginLeft: '15rem' }}>
          <p>Loading...</p>
        </div>
      )}
      {error && (
        <div style={{ marginLeft: '15rem' }}>
          <p>
            Error:
            {error}
          </p>
        </div>
      )}
      {shortenLink && (
        <div className="col-lg-6 col-md-6 mb-4">
          <div className="login-area">
            <h4 className="text-secondary">Shortened URL</h4>
            <div className="col">
              <label />
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  disabled
                  placeholder={shortenLink.shortened_url}
                />
                <button
                  onClick={handleCopy}
                  className="btn btn-outline-secondary"
                  type="button"
                >
                  {copied ? 'Copied!' : 'Copy'}
                </button>
              </div>
              <div
                className="text-left"
                style={{ marginTop: '1rem', display: 'flex' }}
              >
                <button
                  type="button"
                  className="btn btn-primary btnhover w-100 me-2"
                  onClick={handleRedirect}
                >
                  Redirect
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LinkResult;
