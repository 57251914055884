const MenuListArray2 = [
  {
    title: 'User',
    content: [
      {
        title: 'Logout',
        to: './logout',
      },
    ],
  },
  {
    title: 'Login',
    to: './',
  },
];

export default MenuListArray2;
