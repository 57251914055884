import { useState } from 'react';
import InputShortener from './InputShortener';
import LinkResult from './LinkResult';

function Main() {
  const [inputValue, setInputValue] = useState('');
  return (
    <div
      className="container hero"
      style={{ display: 'flex', height: '33.4rem', alignItems: 'center' }}
    >
      <InputShortener setInputValue={setInputValue} />
      <LinkResult inputValue={inputValue} />
    </div>
  );
}

export default Main;
