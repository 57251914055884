import { useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

function ShortLinkRedirect({ match }) {
  const apiUrl = process.env.REACT_APP_SERVER_API_URL;
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}${match.params.hash}`, { maxRedirects: 0 });

        if (response.status === 200) {
          const redirectURL = response.data.target_url;

          if (!redirectURL) {
            history.push('/error_page', { error: 'Invalid URL' });
          } else {
            window.location.href = redirectURL;
          }
        } else {
          throw new Error();
        }
      } catch (err) {
        history.replace('/error_page', { error: 'An error occurred' });
      }
    };

    fetchData();
  }, [apiUrl, match.params.hash, history]);

  return null;
}

export default ShortLinkRedirect;
