import { useState } from 'react';

function InputShortener({ setInputValue }) {
  const [value, setValue] = useState('');

  const handleClick = () => {
    setInputValue(value);
  };

  const resetForm = () => {
    setValue('');
  };

  return (
    <div className="col-lg-6 col-md-6 mb-4">
      <div className="login-area">
        <h4 className="text-secondary">Enter long Url to Make it short</h4>
        <div className="col">
          <label htmlFor="inputUrl">Enter Your URL Here</label>
          <input
            type="text"
            className="form-control"
            placeholder="Paste a link to shorten it"
            value={value}
            id="inputUrl"
            onChange={(e) => setValue(e.target.value)}
          />

          <div
            className="text-left"
            style={{ marginTop: '1rem', display: 'flex' }}
          >
            <button
              type="button"
              className="btn btn-primary btnhover w-100 me-2"
              onClick={handleClick}
            >
              shorten
            </button>
            <button
              type="button"
              className="btn btn-primary btnhover w-100 me-2"
              onClick={resetForm}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InputShortener;
