import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Main from './layout/Main';
import './assets/css/style.css';
import Header from './layout/Header';
import Footer from './layout/Footer';
import ErrorPage from './layout/ErrorPage';

import ShortLinkRedirect from './layout/ShortLinkRedirect';
import Login from './Login';
import { AuthProvider } from './Auth';
import PrivateRoute from './PrivateRoute';
import Logout from './Logout';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Route exact path={['/', '/login', '/shorten', '/error_page', '/logout']} component={Header} />
        <Switch>
          <PrivateRoute path="/shorten" exact component={Main} />
          <Route path="/" exact component={Login} />
          <Route path="/login" exact component={Login} />
          <Route path="/error_page" exact component={ErrorPage} />

          {/* <Route path="/signup" exact component={SignUp} /> */}
          <PrivateRoute path="/logout" exact component={Logout} />

          <Route path="/:hash" component={ShortLinkRedirect} />

        </Switch>
        <Route exact path={['/', '/login', '/shorten', '/error_page', '/logout']} component={Footer} />
      </Router>
    </AuthProvider>
  );
}

export default App;
